define(['app', '$window', '$console', 'accessibilityFocusHelper'], (app, $window, $console, accessibilityFocusHelper) => {

  const postcodeLookup = () => {
    const NO_STORE_FOUND_MESSAGE = '<li class="storeDropDown_noResults">No store can be found</li>';

    const component = {};

    const _config = {
      selectors: {
        headerStoreLocatorDropDown: '.responsiveSubMenu_headerStoreLocator_selectStore',
        postcodeInput: '.postcodeLookup_search',
        storeDropDown: '.storeDropDown',
        storeDropDownSuggestions: '.storeDropDown_suggestions',
        selectAStore: '.storeDropDown_selectStore',
        selectedStore: '.responsiveSubMenu_headerStoreLocatorText_store',
        selectedStoreMobile: '.responsiveSubMenu_headerStoreLocatorText_store_mobile',
        headerLocatorText: '.responsiveSubMenu_headerStoreLocator',
        changeStore: '.responsiveSubMenu_changeStore',
        postcodeLookupBox: '.postcodeLookup',
        postcodeLookupDropdowns: '.postcodeLookup_dropdowns',
        postcodeLookupWrapper: '.responsiveSubMenu_postcodeLookup_wrapper',
        locationDropDown: '.locationDropDown',
        locationDropDownSuggestions: '.locationDropDown_selectStore',
        prevSelectedStore: '.previouslySelectedStore',
        prevSelectedStoreName: '.previouslySelectedStore_storeName',
        showPostcodeLookup: 'show',
        storeSelectedClass: 'storeSelected',
        openingTimes: '.responsiveSubMenu_headerStoreLocatorText_openingTimes',
        postcodeLookupForm: '.postcodeLookup_form',
        noResults: '.storeDropDown_noResults',
        postcodeLookupTitle: '.postcodeLookup_title',
        storeDropDownList: '.storeDropDown_selectStore_list',
        storeLinkMobile: '.storeSelected_mobile',
        locationDropDownScreenReader: '.locationDropDownScreenReader'
      },
      attributes: {
        storeURL: 'data-store-url',
        openingTimes: 'data-opening-time',
        longitude: 'data-longitude',
        latitude: 'data-latitude',
        storePostcode: 'data-store-postcode'
      },
      lSKeys: {
        selectedStore: "selectedStore",
        selectedStoreURL: "selectedStoreURL",
        selectedStoreOpeningTimes: "selectedStoreOpeningTimes",
        selectedStoreLongitude: "selectedStoreLongitude",
        selectedStoreLatitude: "selectedStoreLatitude",
        selectedStorePostcode: "selectedStorePostcode",
        prevSelectedStore: "previouslySelectedStore",
        prevSelectedStoreURL: "previouslySelectedStoreURL",
        prevSelectedStoreOpeningTimes: "previouslySelectedStoreOpeningTimes",
        prevSelectedStoreLongitude: "previouslySelectedStoreLongitude",
        prevSelectedStoreLatitude: "previouslySelectedStoreLatitude",
        prevSelectedStorePostcode: 'prevSelectedStorePostcode',
        currentLocationLatitude: "currentLocationLatitude",
        currentLocationLongitude: "currentLocationLongitude",
        usedGeoLocation: "usedGeoLocation",
        UTCDate: "UTCDate",
      },
      openingTimeKeys: {
        day: "day",
        opening: "opening",
        closing: "closing"
      }
    }

    const _init = (element, userDriven) => {
      component.element = element;
      component.headerStoreLocatorDropDown = component.element.querySelector(component.config.selectors.headerStoreLocatorDropDown);
      component.postcodeInput = component.element.querySelector(component.config.selectors.postcodeInput);
      component.storeDropDown = component.element.querySelector(component.config.selectors.storeDropDown);
      component.selectedStore = component.element.querySelector(component.config.selectors.selectedStore);
      component.selectedStoreMobile = component.element.querySelector(component.config.selectors.selectedStoreMobile);
      component.headerLocatorText = component.element.querySelector(component.config.selectors.headerLocatorText);
      component.changeStore = component.element.querySelector(component.config.selectors.changeStore);
      component.postcodeLookupBox = component.element.querySelector(component.config.selectors.postcodeLookupBox);
      component.postcodeLookupDropdowns = component.element.querySelector(component.config.selectors.postcodeLookupDropdowns);
      component.postcodeLookupWrapper = component.element.querySelector(component.config.selectors.postcodeLookupWrapper);
      component.prevSelectedStore = component.element.querySelector(component.config.selectors.prevSelectedStore);
      component.prevSelectedStoreName = component.element.querySelector(component.config.selectors.prevSelectedStoreName);
      component.openingTimesElem = component.element.querySelector(component.config.selectors.openingTimes);
      component.postcodeLookupForm = component.element.querySelector(component.config.selectors.postcodeLookupForm);
      component.storeLinkMobile = component.element.querySelector(component.config.selectors.storeLinkMobile);
      component.locationDropDownScreenReader = component.element.querySelector(component.config.selectors.locationDropDownScreenReader);

      if (!userDriven) {
        component.handleOutdatedOpeningTimes();
      }

      const useGeoLocation = component.checkLocalStorage();
      if(!component.getFromLS(component.config.lSKeys.usedGeoLocation)) {
        if(useGeoLocation) {
          component.getCurrentLocation();
        }
      }

      app.subscribe("nearByStoresPageUpdate", component.handleStoreSuggestionClicked);
      component.addEventListeners();

      return component;
    }

    const _handleOutdatedOpeningTimes = () => {
      const dateOpeningTimesWasSet = component.getFromLS(component.config.lSKeys.UTCDate);
      const currentDay = String(component.getDate());

      if (currentDay !== dateOpeningTimesWasSet) {
        component.updateOpeningTimes(currentDay);
      }
    }

    const _updateOpeningTimes = (currentDay) => {
      const lon = component.getFromLS(component.config.lSKeys.selectedStoreLongitude);
      const lat = component.getFromLS(component.config.lSKeys.selectedStoreLatitude);
      if (lon !== null && lat !== null) {
        component.ajaxOpeningTimesUpdate(lon, lat, component.config.lSKeys.selectedStoreOpeningTimes, false, currentDay);
      }

      const prevStoreLon = component.getFromLS(component.config.lSKeys.prevSelectedStoreLongitude);
      const prevStoreLat = component.getFromLS(component.config.lSKeys.prevSelectedStoreLatitude);
      if (prevStoreLon !== null && prevStoreLat !== null) {
        component.ajaxOpeningTimesUpdate(prevStoreLon, prevStoreLat, component.config.lSKeys.prevSelectedStoreOpeningTimes, true);
      }
    }

    const _ajaxOpeningTimesUpdate = (lon, lat, lsKey, isPrevDay, currentDay) => {
      app.ajax.get({
        url: `/nearestStore.lookup?longitude=${lon}&latitude=${lat}`,
        success: (response) => {
          const storeData = JSON.parse(response);
          const openingTimes = storeData.openingTimes;
          if (openingTimes !== "") {
            if (isPrevDay) {
              const prevSelectedStoreOpeningTime = component.prevSelectedStoreName.getAttribute(component.config.attributes.openingTimes);
              if (prevSelectedStoreOpeningTime !== null && prevSelectedStoreOpeningTime !== "") {
                component.prevSelectedStoreName.setAttribute(component.config.attributes.openingTimes, openingTimes);
              }
            } else {
              component.openingTimesElem.innerHTML = openingTimes;
            }

            component.setInLS(lsKey, openingTimes);
            if (currentDay !== undefined) {
              component.setInLS(component.config.lSKeys.UTCDate, currentDay);
            }
          }
        },
        error: () => { $console.error('failed to get nearest store') }
      });
    }

    const _addEventListeners = () => {
      component.headerStoreLocatorDropDown.addEventListener('click', component.togglePostcodeLookupModal);
      component.postcodeInput.addEventListener('input', component.getLocations);
      component.changeStore.addEventListener('click', component.togglePostcodeLookupModal);
      component.postcodeLookupForm.addEventListener('submit', (event) => {
        component.submitForm(event);
      });
      component.prevSelectedStoreName.addEventListener('click', component.handlePrevSelectedStoreClicked);
    }

    const _handlePrevSelectedStoreClicked = () => {
      const prevSelectedStoreName = component.getFromLS(component.config.lSKeys.prevSelectedStore);
      const prevSelectedStoreURL = component.getFromLS(component.config.lSKeys.prevSelectedStoreURL);
      const prevSelectedStoreOpeningTimes = component.getFromLS(component.config.lSKeys.prevSelectedStoreOpeningTimes);
      const prevSelectedStoreLongitude = component.getFromLS(component.config.lSKeys.prevSelectedStoreLongitude);
      const prevSelectedStoreLatitude = component.getFromLS(component.config.lSKeys.prevSelectedStoreLatitude);
      const prevSelectedStorePostcode = component.getFromLS(component.config.lSKeys.prevSelectedStorePostcode);

      if (prevSelectedStoreName && prevSelectedStoreOpeningTimes
          && prevSelectedStoreLongitude && prevSelectedStoreLatitude && prevSelectedStoreURL) {
        component.handleStoreSuggestionClicked(
            prevSelectedStoreName,
            prevSelectedStoreURL,
            prevSelectedStoreOpeningTimes,
            prevSelectedStoreLongitude,
            prevSelectedStoreLatitude,
            prevSelectedStorePostcode);
      }
    }

    const _submitForm = (event) => {
      event.preventDefault();
      if(component.postcodeInput.value.length > 0) {
        if(component.postcodeLookupBox.querySelector(component.config.selectors.locationDropDownSuggestions)) {
          component.firstLocationSuggestion =
            component.postcodeLookupBox.querySelector(component.config.selectors.locationDropDownSuggestions);
          component.getStoreSuggestions(component.firstLocationSuggestion);
        } else {
          component.displayStoreNotFoundMessage();
        }
      } else {
        component.displayStoreNotFoundMessage();
      }
    }

    const _checkLocalStorage = () => {
      let userHasChosenPreferredStore = false;

      if(component.getFromLS(component.config.lSKeys.selectedStore)
          && component.getFromLS(component.config.lSKeys.selectedStoreOpeningTimes)
          && component.getFromLS(component.config.lSKeys.selectedStoreURL)) {
        component.setSelectedStore();
        userHasChosenPreferredStore = true;
      }

      if(component.getFromLS(component.config.lSKeys.prevSelectedStore)
          && component.getFromLS(component.config.lSKeys.prevSelectedStoreOpeningTimes)
          && component.getFromLS(component.config.lSKeys.prevSelectedStoreURL)) {
        component.showPrevSelectedStoreElement();
      }

      return !userHasChosenPreferredStore;
    }

    const _getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(component.getNearestStore, component.getLocationError);
        component.setInLS(component.config.lSKeys.usedGeoLocation, true);
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    }

    const _getLocationError = () => {
      console.log('Error finding location');
    }

    const _getNearestStore = (position) => {
      const lat = position.coords.latitude;
      const lon = position.coords.longitude;

      if(!isNaN(lat) && !isNaN(lon) && lat !== null && lon !== null) {
        component.setInLS(component.config.lSKeys.currentLocationLongitude, lon);
        component.setInLS(component.config.lSKeys.currentLocationLatitude, lon);

        app.ajax.get({
          url: `/nearestStore.lookup?longitude=${lon}&latitude=${lat}`,
          success: component.nearestStoreSuccessHandler,
          error: () => { $console.error('failed to get nearest store') }
        });
      }
    }

    const _nearestStoreSuccessHandler = (response) => {
      const storeData = JSON.parse(response);
      const storeName = storeData.name;
      const storeURL = storeData.storeURL;
      const storeOpeningTimes = storeData.openingTimes;
      const longitude = storeData.longitude;
      const latitude = storeData.latitude;
      const postcode = storeData.postCode;

      if (storeName === "" || storeURL === "" || storeOpeningTimes === "" || longitude === null ||
          longitude === undefined || latitude === null || latitude === undefined || postcode === "") {
        return;
      }
      // When the user shares their location we want to basically get the nearest store and
      // perform the same operations as when a store suggestion is clicked in the lookup dropdown
      component.handleStoreSuggestionClicked(
          storeName,
          storeURL,
          storeOpeningTimes,
          longitude,
          latitude,
          postcode);
    }

    const _setSelectedStore = () => {
      component.selectedStore.innerHTML = component.getFromLS(component.config.lSKeys.selectedStore);
      component.selectedStore.setAttribute('href', component.getFromLS(component.config.lSKeys.selectedStoreURL));
      component.selectedStoreMobile.innerHTML = component.getFromLS(component.config.lSKeys.selectedStore);
      component.openingTimesElem.innerHTML = component.getFromLS(component.config.lSKeys.selectedStoreOpeningTimes);
      component.headerLocatorText.classList.add(component.config.selectors.storeSelectedClass);
      component.storeLinkMobile.setAttribute('href', component.getFromLS(component.config.lSKeys.selectedStoreURL));
      app.publish('headerSelectedStore', component.selectedStore.innerHTML);
    }

    const _getLocations = () => {
      if(component.postcodeInput.value.length > 0) {
        app.ajax.get({
          url: `/recommendedLocations.lookup?location=${component.postcodeInput.value}`,
          success: component.postcodeLookupSuccessHandler,
          error: () => { $console.error('failed to get recommended locations') }
        });
      } else {
        component.locationDropDown && component.locationDropDown.classList.remove(component.config.selectors.showPostcodeLookup);
      }
    }

    const _postcodeLookupSuccessHandler = (response) => {
      if(response) {
        component.locationDropDown && component.postcodeLookupDropdowns.removeChild(component.locationDropDown);
        component.postcodeLookupDropdowns.insertAdjacentHTML('afterbegin', response);

        component.locationDropDownSuggestions =
            component.postcodeLookupDropdowns.querySelectorAll(component.config.selectors.locationDropDownSuggestions);

        component.locationDropDownScreenReader.innerHTML = component.locationDropDownSuggestions.length + " locations found";
        component.locationDropDownSuggestions && component.locationDropDownSuggestions.forEach(suggestion => {
            suggestion.addEventListener('click', () => { component.getStoreSuggestions(suggestion) });
        });

        component.locationDropDown = component.element.querySelector(component.config.selectors.locationDropDown);
        component.locationDropDown.classList.add(component.config.selectors.showPostcodeLookup);
        component.firstChild = component.locationDropDown.firstChild;
        component.element.addEventListener('keydown', component.handleKeyDown);
      }
    }

    const _getStoreSuggestions = (location) => {
      component.tidyUpStoreDropdown();
      component.locationDropDown.classList.remove(component.config.selectors.showPostcodeLookup);
      const loader = component.element.querySelector('.loaderLookup');
      loader.style.display = "block";
      const latitude = location.getAttribute(component.config.attributes.latitude);
      const longitude = location.getAttribute(component.config.attributes.longitude);

      if (isNaN(latitude) || isNaN(longitude) || latitude === null || longitude === null || longitude === "" || latitude === "") {
        component.displayStoreNotFoundMessage();
      }

      app.ajax.get({
        url: `/nearestStores.lookup?lon=${longitude}&lat=${latitude}`,
        success: component.storeLookupSuccessHandler,
        error: () => { $console.error('failed to get nearest store') }
      });
    }

    const _storeLookupSuccessHandler = (response) => {
      if(response) {
        const loader = component.element.querySelector('.loaderLookup');
        loader.style.display = "none";
        component.storeDropDown.insertAdjacentHTML('beforeend', response);
        component.selectAStore = component.storeDropDown.querySelector(component.config.selectors.selectAStore);
        component.storeDropDownSuggestions =
            component.storeDropDown.querySelectorAll(component.config.selectors.storeDropDownSuggestions);
        component.storeDropDownSuggestions && component.storeDropDownSuggestions.forEach(suggestion => {
          suggestion.addEventListener('click', () => {
            component.handleStoreSuggestionClicked(
                suggestion.innerText,
                suggestion.getAttribute(component.config.attributes.storeURL),
                suggestion.getAttribute(component.config.attributes.openingTimes),
                suggestion.getAttribute(component.config.attributes.longitude),
                suggestion.getAttribute(component.config.attributes.latitude),
                suggestion.getAttribute(component.config.attributes.storePostcode))
          });
        });

        component.storeDropDown.classList.add(component.config.selectors.showPostcodeLookup);
        component.locationDropDown.classList.remove(component.config.selectors.showPostcodeLookup);
      }
    }

    const _handleStoreSuggestionClicked = (selectedStore, selectedStoreURL, openingTimes,
        longitude, latitude, storePostcode) => {
      const currentlySelectedStore = component.getFromLS(component.config.lSKeys.selectedStore);
      const currentlySelectedStoreURL = component.getFromLS(component.config.lSKeys.selectedStoreURL);
      const currentlySelectedStoreOpeningTimes = component.getFromLS(component.config.lSKeys.selectedStoreOpeningTimes);
      const currentlySelectedStoreLongitude = component.getFromLS(component.config.lSKeys.selectedStoreLongitude);
      const currentlySelectedStoreLatitude = component.getFromLS(component.config.lSKeys.selectedStoreLatitude);
      const currentlySelectedStorePostcode = component.getFromLS(component.config.lSKeys.selectedStorePostcode);

      if(currentlySelectedStore && currentlySelectedStoreOpeningTimes
          && currentlySelectedStoreLongitude && currentlySelectedStoreLatitude
          && currentlySelectedStoreURL) {
        component.setInLS(component.config.lSKeys.prevSelectedStore, currentlySelectedStore);
        component.setInLS(component.config.lSKeys.prevSelectedStoreURL, currentlySelectedStoreURL);
        component.setInLS(component.config.lSKeys.prevSelectedStoreOpeningTimes, currentlySelectedStoreOpeningTimes);
        component.setInLS(component.config.lSKeys.prevSelectedStoreLongitude, currentlySelectedStoreLongitude);
        component.setInLS(component.config.lSKeys.prevSelectedStoreLatitude, currentlySelectedStoreLatitude);
        component.setInLS(component.config.lSKeys.prevSelectedStorePostcode, currentlySelectedStorePostcode);

        component.showPrevSelectedStoreElement();
      }

      component.setInLS(component.config.lSKeys.selectedStore, selectedStore);
      component.setInLS(component.config.lSKeys.selectedStoreURL, selectedStoreURL);
      component.setInLS(component.config.lSKeys.selectedStoreOpeningTimes, openingTimes);
      component.setInLS(component.config.lSKeys.selectedStoreLongitude, longitude);
      component.setInLS(component.config.lSKeys.selectedStoreLatitude, latitude);
      component.setInLS(component.config.lSKeys.selectedStorePostcode, storePostcode);
      component.setInLS(component.config.lSKeys.UTCDate, component.getDate());

      component.setSelectedStore();
      component.hidePostcodeLookupModal();
      component.cleanupElements();
      component.storeDropDown.classList.remove(component.config.selectors.showPostcodeLookup);
      app.publish('storeDetailHeaderUpdate');
      app.publish('storeSelected/postcodeLookup');
      component.selectedStore.setAttribute('aria-label', 'selected nearest store ' + selectedStore);
      accessibilityFocusHelper.focus(component.selectedStore);
    }

    const _showPostcodeLookupModal = () => {
      component.changeStore.setAttribute('aria-expanded', true);
      component.postcodeLookupWrapper.classList.add(component.config.selectors.showPostcodeLookup);
      component.postcodeLookupTitle = component.element.querySelector(component.config.selectors.postcodeLookupTitle);
      accessibilityFocusHelper.focus(component.postcodeLookupTitle);
      $window.addEventListener('click', (event) => {
        if(event.target === component.postcodeLookupWrapper ||
          component.postcodeLookupWrapper.contains(event.target) ||
          event.target === component.headerLocatorText ||
          component.headerLocatorText.contains(event.target)) {
          return;
        }
        else {
          component.hidePostcodeLookupModal();
        }
      })

      $window.addEventListener('keydown', (event)  => {
        if (event.key === 'Tab' && event.target === component.prevSelectedStoreName) {
          component.hidePostcodeLookupModal();
        }
      })
    }

    const _showPrevSelectedStoreElement = () => {
      const prevSelectedStoreName = component.getFromLS(component.config.lSKeys.prevSelectedStore);
      const prevSelectedStoreOpeningTimes = component.getFromLS(component.config.lSKeys.prevSelectedStoreOpeningTimes);

      if(prevSelectedStoreName && prevSelectedStoreOpeningTimes) {
        component.prevSelectedStoreName.innerHTML = prevSelectedStoreName;
        component.prevSelectedStoreName.setAttribute('data-opening-time', prevSelectedStoreOpeningTimes);
        component.prevSelectedStore.classList.add(component.config.selectors.showPostcodeLookup);
      }
    }

    const _cleanupElements = () => {
      component.postcodeInput.value = '';
      component.locationDropDown && component.locationDropDown.remove();
      component.storeDropDownSuggestions && component.storeDropDownSuggestions.forEach(suggestion => suggestion.remove());
      component.storeDropDownSuggestions = null;
      component.selectAStore && component.selectAStore.remove();
      component.locationDropDown = null;
      component.changeStore.setAttribute('aria-expanded', false);
    }

    const _togglePostcodeLookupModal = () => {
      component.expanded = !(component.postcodeLookupWrapper.classList.contains(component.showPostcodeLookup));
      if (component.postcodeLookupWrapper.classList.contains(component.config.selectors.showPostcodeLookup)) {
        component.hidePostcodeLookupModal();
      } else {
        component.showPostcodeLookupModal();
      }

      if (component.expanded) {
        document.addEventListener('keydown', component.escapeListener);
        accessibilityFocusHelper.focus(component.changeStore);
      } else {
        document.removeEventListener('keydown', component.escapeListener);
      }
    }

    const _hidePostcodeLookupModal = () => {
      component.postcodeLookupWrapper.classList.remove(component.config.selectors.showPostcodeLookup);
      component.storeDropDown.classList.remove(component.config.selectors.showPostcodeLookup);
      component.cleanupElements();
    }

    const _escapeListener = event => {
      if (event.key === 'Escape' || event.key === 'Esc') {
        component.hidePostcodeLookupModal();
        accessibilityFocusHelper.focus(component.changeStore);
      }
    };

    const _getFromLS = (localStorageKey) => {
      return $window.localStorage.getItem(localStorageKey);
    }

    const _setInLS = (localStorageKey, value) => {
      $window.localStorage.setItem(localStorageKey, value);
    }

    const _removeFromLS = (localStorageKey) => {
      $window.localStorage.removeItem(localStorageKey);
    }

    const _displayStoreNotFoundMessage = () => {
      component.tidyUpStoreDropdown();
      component.storeDropDown.insertAdjacentHTML('beforeend', NO_STORE_FOUND_MESSAGE);
      component.storeDropDown.classList.add(component.config.selectors.showPostcodeLookup);
      if (component.locationDropDown) {
        component.locationDropDown.classList.remove(component.config.selectors.showPostcodeLookup);
      }
    }

    const _tidyUpStoreDropdown = () => {
      component.storeDropDownList = component.element.querySelector(component.config.selectors.storeDropDownList);
      if(component.storeDropDownList) {
        component.storeDropDown.removeChild(component.storeDropDownList);
        component.selectAStore = component.storeDropDown.querySelector(component.config.selectors.selectAStore);
        if(component.selectAStore) {
          component.storeDropDown.removeChild(component.selectAStore);
        }
      }

      component.noResults = component.element.querySelector(component.config.selectors.noResults);
      if(component.noResults) {
        component.storeDropDown.removeChild(component.noResults);
      }
    }

    const _handleKeyDown = (e) => {
      if (e.keyCode == '38') {
        e.preventDefault();
        if (document.activeElement == component.firstChild.nextSibling) {
          accessibilityFocusHelper.focus(component.postcodeInput);
        }
        else {accessibilityFocusHelper.focus(document.activeElement.previousSibling.previousSibling); }
      }
      else if (e.keyCode == '40') {
        e.preventDefault();
        if (document.activeElement == component.postcodeInput) { accessibilityFocusHelper.focus(component.firstChild.nextSibling); }
        else { accessibilityFocusHelper.focus(document.activeElement.nextSibling.nextSibling); }
      }
    }

    const _getDate = () => {
      return new Date().getUTCDate();
    }

    component.config = _config;
    component.init = _init;
    component.getLocations = _getLocations;
    component.showPostcodeLookupModal = _showPostcodeLookupModal;
    component.checkLocalStorage = _checkLocalStorage;
    component.addEventListeners = _addEventListeners;
    component.getStoreSuggestions = _getStoreSuggestions;
    component.setSelectedStore = _setSelectedStore;
    component.togglePostcodeLookupModal = _togglePostcodeLookupModal;
    component.hidePostcodeLookupModal = _hidePostcodeLookupModal;
    component.escapeListener = _escapeListener;
    component.postcodeLookupSuccessHandler = _postcodeLookupSuccessHandler;
    component.storeLookupSuccessHandler = _storeLookupSuccessHandler;
    component.handleStoreSuggestionClicked = _handleStoreSuggestionClicked;
    component.cleanupElements = _cleanupElements;
    component.showPrevSelectedStoreElement = _showPrevSelectedStoreElement;
    component.handlePrevSelectedStoreClicked = _handlePrevSelectedStoreClicked;
    component.handleKeyDown = _handleKeyDown;
    component.submitForm = _submitForm;
    component.getNearestStore = _getNearestStore;
    component.nearestStoreSuccessHandler = _nearestStoreSuccessHandler;
    component.getCurrentLocation = _getCurrentLocation;
    component.getLocationError = _getLocationError;
    component.getFromLS = _getFromLS;
    component.setInLS = _setInLS;
    component.removeFromLS = _removeFromLS;
    component.displayStoreNotFoundMessage = _displayStoreNotFoundMessage;
    component.tidyUpStoreDropdown = _tidyUpStoreDropdown;
    component.updateOpeningTimes = _updateOpeningTimes;
    component.ajaxOpeningTimesUpdate = _ajaxOpeningTimesUpdate;
    component.getDate = _getDate;
    component.handleOutdatedOpeningTimes = _handleOutdatedOpeningTimes;

    return component;
  };

  return postcodeLookup;
});
